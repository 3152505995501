/**
 * PageArticle
 */

import React from 'react';
import Hero from 'Components/Hero';
import Wysiwyg from 'Components/Wysiwyg';
import Breadcrumbs from 'Components/Breadcrumbs';
import StreamField from 'Components/StreamField';
import CardResourceList from 'Components/CardResourceList';
import ButtonAI from 'Components/ButtonAI';
import { BaseWrap } from 'Containers/Layouts';
import s from './PageResourceList.module.scss';

const PageResourceList = ({
    hero,
    wysiwyg,
    breadcrumbs,
    items,
    hideResourceList,
    resourceList,
    id,
    ai,
}) => {
    return (
        <div className={s['PageResourceList']}>
            <Breadcrumbs {...breadcrumbs} />
            <Hero {...hero} />

            {ai && <ButtonAI {...ai} modifiers={["PageResourceList", "Wrap"]} />}

            {wysiwyg && (
                <div className={s['PageResourceList__Wysiwyg']}>
                    <Wysiwyg wysiwyg={wysiwyg} />
                </div>
            )}

            {!hideResourceList &&
                <div className={s['PageResourceList__ResourceList']}>
                    <CardResourceList
                        {...resourceList}
                        showDropdownFilterField={!resourceList.categoryFilter.length}
                        showFilter={true}
                    />
                </div>
            }

            {items && <StreamField items={items} currentPage={id} />}
        </div>
    );
};

PageResourceList.defaultProps = {
    items: [],
    hideResourceList: false,
    resourceList: {
        categoryFilter: [],
    },
};

export default BaseWrap(PageResourceList);
